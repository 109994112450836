import { Col, Row } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import Text from "../../components/Text";
import React, { useEffect, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import wifi from "../../assets/Wifi.png";
import apps from "../../assets/Apps.jpg";
import cards from "../../assets/Card.png";
import { useQuery } from "@apollo/client";
import { LOAD_PLATFORM_OVERVIEW } from "../../graphql";
import { formatNumberUniversal } from "../../utils";
import BigNumber from "bignumber.js";

const PlatformOverview = () => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const { error, loading, data } = useQuery(LOAD_PLATFORM_OVERVIEW);
  const [totals, setTotals] = useState({
    totalValueLockedUSD: new BigNumber(0),
    volumeUSD: new BigNumber(0),
    untrackedVolumeUSD: new BigNumber(0),
    totalValueLockedETH: new BigNumber(0),
    txCount: new BigNumber(0),
    feesUSD: new BigNumber(0),
  });

  useEffect(() => {
    const calculateTotals = () => {
      const initialTotals = {
        totalValueLockedUSD: new BigNumber(0),
        volumeUSD: new BigNumber(0),
        untrackedVolumeUSD: new BigNumber(0),
        totalValueLockedETH: new BigNumber(0),
        txCount: new BigNumber(0),
        feesUSD: new BigNumber(0),
      };

      console.log(data.pools);

      const result = data.pools.reduce((acc: any, pool: any) => {
        acc.totalValueLockedUSD = acc.totalValueLockedUSD.plus(
          new BigNumber(pool.totalValueLockedUSD)
        );
        acc.volumeUSD = acc.volumeUSD.plus(new BigNumber(pool.volumeUSD));
        acc.untrackedVolumeUSD = acc.untrackedVolumeUSD.plus(
          new BigNumber(pool.untrackedVolumeUSD)
        );
        acc.totalValueLockedETH = acc.totalValueLockedETH.plus(
          new BigNumber(pool.totalValueLockedETH)
        );
        acc.txCount = acc.txCount.plus(new BigNumber(pool.txCount));
        acc.feesUSD = acc.feesUSD.plus(new BigNumber(pool.feesUSD));
        return acc;
      }, initialTotals);

      setTotals(result);
    };

    if (data) {
      calculateTotals();
    }
  }, [data]);

  return (
    <Row
      style={{
        width: "100%",
        padding: md ? "36px" : sm ? "24px" : "16px",
        borderRadius: "12px",
        backgroundColor: "#252527",
      }}
    >
      <Text
        size={"lg"}
        style={{
          fontSize: md ? "32px" : "28px",
          width: "100%",
        }}
      >
        Platform Overview
      </Text>
      <div
        style={{
          display: "flex",
          flexDirection: md ? "row" : "column",
          gap: "16px",
          width: "100%",
          marginTop: "24px",
        }}
      >
        <div style={{ flex: md ? "1" : "none", width: md ? "auto" : "100%" }}>
          <PlatformOverviewCards
            title="Total Value Locked"
            icon={cards}
            value={`$${formatNumberUniversal(
              totals.totalValueLockedUSD.toFixed()
            )}`}
          />
        </div>
        <div style={{ flex: md ? "1" : "none", width: md ? "auto" : "100%" }}>
          <PlatformOverviewCards
            title="Total Volume"
            icon={wifi}
            value={`$${formatNumberUniversal(totals.volumeUSD.toFixed())}`}
          />
        </div>
        <div style={{ flex: md ? "1" : "none", width: md ? "auto" : "100%" }}>
          <PlatformOverviewCards
            title="Total Transactions"
            icon={wifi}
            value={`${totals.txCount.toFixed()}`}
          />
        </div>
        <div style={{ flex: md ? "1" : "none", width: md ? "auto" : "100%" }}>
          <PlatformOverviewCards
            title="Total fee Accrued"
            icon={cards}
            value={`$${formatNumberUniversal(totals.feesUSD.toFixed())}`}
          />
        </div>
      </div>
    </Row>
  );
};

export default PlatformOverview;

interface PlatformOverviewCardsProps {
  icon: string;
  title: string;
  value: string;
}

const PlatformOverviewCards = (props: PlatformOverviewCardsProps) => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const { icon, title, value } = props;
  return (
    <Col
      style={{
        padding: "24px",
        borderRadius: "12px",
        backgroundColor: "#37373C",
        border: "1px solid #2E6BCC",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        alignItems: md ? "flex-start" : "center",
      }}
    >
      <div
        style={{
          height: "52px",
          width: "52px",
          backgroundColor: "#2B66F6",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={icon} />
      </div>
      <Row
        style={{
          flexDirection: "column",
          gap: "4px",
          width: "100%",
          alignItems: md ? "flex-start" : "center",
        }}
      >
        <Col>
          {" "}
          <Text size="sm" style={{ color: "#9B9CA3" }}>
            {title}
          </Text>
          <InfoCircleOutlined style={{ color: "#9B9CA3", marginLeft: "4px" }} />
        </Col>
        <Text
          size="sm"
          style={{
            color: "#A3D3FB",
            fontSize: xl ? "28px" : md ? "20px" : "28px",
            fontWeight: "600",
          }}
        >
          {value}
        </Text>
      </Row>
    </Col>
  );
};
